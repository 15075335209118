#Header {
  width: 100% !important;
  background-color: #dbdbdb !important;
}
.navbar {
  background-color: #dbdbdb !important;
}
.navbar-menu {
  background-color: #dbdbdb !important;
}
a.navbar-item:focus {
  background-color: #dbdbdb !important;
}

.buymecoffe {
  align-items: center;
  display: flex;
  padding: 0.5rem 0rem;
}
.buymecoffe img {
  max-height: 48px !important;
  padding-left: 0;
  height: '60px';
  width: '100%';
}

/* Média query pour les téléphones et tablettes */
@media screen and (max-width: 850px) {
  .buymecoffe {
    padding: 0.5rem 0.75rem;
  }
}
