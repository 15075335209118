.picture {
  margin: 0 auto;
  width: 200px;
}
.image-circular {
  width: 200px;
  height: 250px;
  border-radius: 5px;
  border: 5px solid #363636;
}
.centered {
  width: 60%;
  margin: 0 auto;
}
.iconeNetwork {
  width: 1em;
}

.buymecoffe {
  align-items: center;
  display: flex;
  padding: 0.5rem 0rem;
}
.buymecoffe img {
  max-height: 48px !important;
  padding-left: 0;
  height: '60px';
  width: '100%';
}

/* Média query pour les téléphones et tablettes */
@media screen and (max-width: 850px) {
  .buymecoffe {
    padding: 0.5rem 0.75rem;
  }
}
