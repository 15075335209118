#technology {
  background-color: #dbdbdb;
}

#project {
  background-color: #dbdbdb;
}
.project {
  background-color: #dbdbdb;
}
